import moment from "moment";

export default {
    data() {
        return {
            dateCampaign: {
                started: null,
                ended: null,
                isReady: false,
                checkEvery: 20
            },
        }
    },
    methods: {
        _dateCampaign_setTimeInterval() {
            window.setInterval(this._dateCampaign_checkCampaign, this.dateCampaign.checkEvery * 1000);
        },
        _dateCampaign_checkCampaign() {
            this._dateCampaign_checkCampaignStarted();
            this._dateCampaign_checkCampaignEnded();
            this.dateCampaign.isReady = true;
        },
        _dateCampaign_checkCampaignStarted() {
            const startCampaign = this._store_getAuthSettingsProp('start_campaign') || {};
            const startDate = startCampaign.date || null;
            this.dateCampaign.started = startDate ? moment(startDate).isBefore() : true;
        },
        _dateCampaign_checkCampaignEnded() {
            const endCampaign = this._store_getAuthSettingsProp('end_campaign') || {};
            const endDate = endCampaign.date || null;
            this.dateCampaign.ended = endDate ? moment(endDate).isBefore() : false;
        }
    },
    created() {
        this._dateCampaign_checkCampaign();
        this._dateCampaign_setTimeInterval();
    }
}
