import CampaignEndedMixin from "./CampaignEndedMixin";
import CommonMixin from "./CommonMixin";

export default {
    mixins: [
        CommonMixin,
        CampaignEndedMixin
    ],
    data() {
        return {
            campaign: {
                aggregate: null,
                init: false,
                lastTimestamp: 0
            }
        }
    },
    methods: {
        _campaign_setDataChangeEvents() {
            this._common_getAggregate().on('value', s => {
                this.campaign.aggregate = s.val();
                this.campaign.init = true;
            });

            this._common_getAggregate().child('latest').on('value', s => {
                let donations = s.val();

                if (!this.campaign.init || !donations) return;

                let donation = [...donations].shift();

                if (donation.timestamp > this.campaign.lastTimestamp) {
                    this.campaign.lastTimestamp = donation.timestamp;
                    this._hook_onNewDonationCreated(donation);
                }
            });
        },
        // eslint-disable-next-line no-unused-vars
        _hook_onNewDonationCreated(donation) {
            // implement donation
        },
        _campaign_redirectIfWrongCampaign() {
            const currentCampaign = this.$route.name;
            const settingsCampaign = this._store_getAuthSettingsProp('campaign');

            if (currentCampaign !== settingsCampaign) {
                this.$router.push({name: settingsCampaign});
            }
        }
    },
    created() {
        this._campaign_redirectIfWrongCampaign();
        this._campaign_setDataChangeEvents();
    }
}
