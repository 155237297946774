export default class DateHelper
{
    /**
     * JavaScript uses milliseconds as a timestamp, whereas PHP uses seconds.
     * As a result, you get very different dates, as it is off by a factor 1000.
     */
    static fromPhpTimestamp(value) {
        return (typeof value === 'number' && String(value).length <= 10)
            ? value * 1000
            : value;
    }
}
