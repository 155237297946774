import {format} from "@vitorccs/v-money/src/utils";
import * as moment from 'moment';
import moneyPtBr from "../lang/vMoneyPtBr";
import 'moment/locale/pt-br';
import DateHelper from "./DateHelper";

export default class Formatter {

    static formatFromNow(value) {
        if (!value) return null;
        value = DateHelper.fromPhpTimestamp(value);
        value = moment(value).fromNow();
        return value;
    }

    static formatDateTimeSec(value) {
        if (!value) return null;
        value = DateHelper.fromPhpTimestamp(value);
        value = moment(value).format('DD/MM/YYYY HH:mm:ss');
        return value;
    }

    static formatDateTime(value) {
        if (!value) return null;
        value = DateHelper.fromPhpTimestamp(value);
        value = moment(value).format('DD/MM/YYYY HH:mm');
        return value;
    }

    static formatDate(value) {
        if (!value) return null;
        value = DateHelper.fromPhpTimestamp(value);
        value = moment(value).format('DD/MM/YYYY');
        return value;
    }

    static formatDecimal(value, precision = 2) {
        return this.formatCurrency(value, false, precision);
    }

    static formatCurrency(value, addPrefix = true, precision = 2) {
        let settings = JSON.parse(JSON.stringify(moneyPtBr));

        if (!addPrefix) settings.prefix = '';
        if (precision !== 2) settings.precision = precision;
        if (typeof value === 'string') value = parseFloat(value);

        value = format(value, settings);

        return value;
    }

    static formatFrequency(value) {
        let labels = {
            'onetime': 'Avulsa',
            'monthly': 'Mensal'
        };
        return typeof labels[value] !== 'undefined' ? labels[value] : '';
    }

    static formatPaymentMethod(value) {
        let labels = {
            'bank_slip': 'Boleto Bancário',
            'credit_card': 'Cartão de Crédito',
            'debit_card': 'Cartão de Dédito',
            'bank_debit': 'Cartão de Dédito'
        };
        return typeof labels[value] !== 'undefined' ? labels[value] : '';
    }

    static capitalize(string) {
        return String(string)
            .trim()
            .toLowerCase()
            .split(' ')
            .map((word) => word[0].toUpperCase() + word.substr(1))
            .join(' ');
    }

    static formatFullName(name) {
        let parts = String(name).trim().split(' ');
        let fullName = parts.length > 1 ? parts[0] + ' ' + parts[parts.length - 1] : parts[0];

        return this.capitalize(fullName);
    }
}
