import Vue from 'vue';
import Formatter from '../helpers/Formatter';

// set filters
Vue.filter('formatCurrency', function (value, addPrefix = true, precision = 2) {
    return Formatter.formatCurrency(value, addPrefix, precision) || '-';
});

Vue.filter('formatDate', function (value) {
    return Formatter.formatDate(value) || '-';
});

Vue.filter('formatDateTime', function (value) {
    return Formatter.formatDateTime(value) || '-';
});

Vue.filter('formatDateTimeSec', function (value) {
    return Formatter.formatDateTimeSec(value) || '-';
});

Vue.filter('formatDecimal', function (value, precision = 2) {
    return Formatter.formatDecimal(value, precision) || '-';
});

Vue.filter('formatFrequency', function (value) {
    return Formatter.formatFrequency(value);
});

Vue.filter('formatPaymentMethod', function (value) {
    return Formatter.formatPaymentMethod(value);
});

Vue.filter('formatKilograms', function (value) {
    return String(parseInt(value)) + 'Kg';
});

Vue.filter('formatFromNow', function (value) {
    return Formatter.formatFromNow(value) || '-';
});

Vue.filter('formatFullName', function (value) {
    return Formatter.formatFullName(value);
});


