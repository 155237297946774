export default class DataHelper {

    static getArrayTop(array, limit) {
        return [...array].splice(0, limit);
    }

    static roundNumber(value, places = 2) {
        const pow = Math.pow(10, places);
        return Math.round( value * pow + Number.EPSILON ) / pow;
    }

    static floorNumber(value) {
        return Math.floor(value);
    }

    static getFirstStringPart(name) {
        let parts = String(name).trim().split(' ');
        return parts[0];
    }
}
