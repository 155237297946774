import CommonMixin from "../../mixins/CommonMixin";

export default {
    mixins: [CommonMixin],
    data() {
        return {
            dateField: 'end_campaign',
            title: null,
            message: null,
            pictureUrl: null
        }
    },
    methods: {
        loadParameters() {
            const dateCampaign = this._store_getAuthSettingsProp(this.dateField) || {};
            const picture = dateCampaign.picture || null;

            this.title = dateCampaign.title || this.title || null;
            this.message = dateCampaign.message || this.message || null;

            if (picture) {
                this._common_getUserAsset(picture)
                    .getDownloadURL()
                    .then(url => this.pictureUrl = url);
            }
        }
    },
    created() {
        this.loadParameters();
    }
}
